import { Link } from "@StarberryUtils";
import React, { useEffect, useState, useRef } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Button, Navbar, Nav, Form, Container, FormControl, Row, Col, dark, Card, Tabs, Tab } from "react-bootstrap";
import "./Steps.scss";
import CalendarBlock from "../CalendarBlock/CalendarBlock";

const Steps = (props) => {

  return (
    <React.Fragment>

      <div className="tab-box">
        <Tabs defaultActiveKey="selling" id="uncontrolled-tab-example">
          <Tab eventKey="selling" title="Sales">

            <div className="tab-box-form">
              <Form>

                <div className="form-input-content">
                  <Form.Group>
                    <div className="addon-box">
                      <span className="addon-tab-input"><i className="icon-map-marker-form"></i></span><Form.Control type="text" placeholder="Start typing your address" />
                    </div>
                  </Form.Group>
                  <Form.Text className="d-md-none">or <Link href="#">Enter your address manually</Link></Form.Text>
                  <Button variant="yellow" type="Confirm">Confirm</Button>
                </div>
                <Form.Text className="d-none d-md-block">or <Link href="#">Enter your address manually</Link></Form.Text>

              </Form>
            </div>

          </Tab>
          <Tab eventKey="letting" title="Lettings">
          </Tab>
        </Tabs>
      </div>

      <div className="tab-box tab-box2">
        <Tabs defaultActiveKey="your-details" id="uncontrolled-tab-example">
          <Tab eventKey="your-details" title="Your Details">
            <div className="tab-box-form">
              <div className="tab-outline-buttons">
                <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeIn"><Link className="btn btn-outline" href="#" target="_blank"><i className="icon-facebook-form"></i> <span>Continue with Facebook</span></Link></ScrollAnimation>
                <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeIn"><Link className="btn btn-outline" href="#" target="_blank"><i className="icon-google-form"></i> <span>Continue with Google</span></Link></ScrollAnimation>
              </div>
              <div className="tab-form-head">
                <span>or fill out your details</span>
              </div>
              <Form className="form-w-label">
                <Form.Group>
                  <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp">
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control autoComplete="off" type="text" placeholder="" />
                  </ScrollAnimation>
                </Form.Group>

                <Form.Group>
                  <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control autoComplete="off" type="email" placeholder="" />
                  </ScrollAnimation>
                </Form.Group>

                <Form.Group>
                  <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp">
                    <Form.Label>Telephone Number</Form.Label>
                    <Form.Control autoComplete="off" type="number" placeholder="" />
                  </ScrollAnimation>
                </Form.Group>
                <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp">
                  <Button variant="yellow" type="submit" className="w-md-100 mtop-btn ">Continue</Button>
                </ScrollAnimation>
              </Form>
            </div>
          </Tab>
          <Tab eventKey="date-time" title="Date & Time">
            <div className="tab-box-form calender-container">
              <CalendarBlock />
            </div>
          </Tab>
        </Tabs>
      </div>

      <div className="tab-box tab-box2 card-box">
        <div className="tab-box-form">
          <div className="cardbox-content">
            <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInDown">
              <p>Your request has been sent to our team and we will contact you shortly to confirm your appointment and explain the valuation process in more detail. Lorem ipsum dolor sit amet consectetur.</p>
            </ScrollAnimation>
            <div className="what-to-do-text">
              <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp">
                <h6>What to do next?</h6>
                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
              </ScrollAnimation>
            </div>
            <div className="tab-outline-buttons btn-outline-group2">
              <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp"><Link className="btn btn-outline" href="#" target="_blank"><span>Find a Property</span></Link></ScrollAnimation>
              <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp"><Link className="btn btn-outline mb-0" href="#" target="_blank"><span>Contact Us</span></Link></ScrollAnimation>
            </div>
          </div>


        </div>
      </div>
    </React.Fragment>
  );
};
export default Steps;
