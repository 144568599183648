import React, { useState, useEffect } from "react"
import { Button, Form, Container, Row, Col, Fade } from "react-bootstrap"
import "react-calendar/dist/Calendar.css"
import "./CalendarBlock.scss"
import DatePicker, { registerLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import setHours from "date-fns/setHours"
import setMinutes from "date-fns/setMinutes"
import enGb from "date-fns/locale/en-GB"
import moment from "moment"
function disabledDate(current) {
  // Can not select sundays and predfined days
  return current && current < moment().endOf("day")
  // moment(current).day() === 0
}
registerLocale("en-gb", enGb)
var date = new Date()
var mindate = date.setDate(date.getDate() + 1)
const disableWeekends = current => {
  return current.day() !== 0 && current.day() !== 6
}
const CalendarBlock = props => {
  const [startDate, setStartDate] = useState(
    setHours(setMinutes(new Date(mindate), 0), 10)
  )

  const [startingDate, setStartingDate] = useState(
    setHours(setMinutes(new Date(mindate), 0), 10)
  )

  const handleDateSelect = date => {
    //console.log('aaaaaaaaa',startingDate)
  }

  useEffect(() => {
    copyDiv()
  }, [])
  const copyDiv = () => {
    const timeInputDesktop = document.querySelector(
      ".desktop_view_date div.react-datepicker-time__input"
    )
    const currentMonthDesktop = document.querySelector(
      ".desktop_view_date div.react-datepicker__header__dropdown--scroll"
    )
    currentMonthDesktop.appendChild(timeInputDesktop)

    const timeInputMobile = document.querySelector(
      ".mobile_view_date div.react-datepicker-time__input"
    )
    const currentMonthMobile = document.querySelector(
      ".mobile_view_date div.react-datepicker__header__dropdown--scroll"
    )
    currentMonthMobile.appendChild(timeInputMobile)
  }
  const isWeekday = date => {
    var day = moment(date).weekday()
    return day !== 0
  }

  const d = new Date(startDate)
  const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d)
  const mo = new Intl.DateTimeFormat("en", { month: "long" }).format(d)
  const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d)
  var hours = startDate.getHours()
  var minutes = startDate.getMinutes()
  var ampm = hours >= 12 ? "pm" : "am"
  hours = hours % 12
  hours = hours ? hours : 12 // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes
  var strTime = hours + ":" + minutes + " " + ampm

  var valuationdate = da + " " + mo + " " + ye + " - " + strTime
  // const disableWeekends = current => {
  //   return current.day() !== 0 && current.day() !== 6;
  // }
  return (
    <div className="calendar-block">
      {/* {moment(startDate).weekday()} */}
      {props.valuationType !== "Instant_Valuation" && (
        <input type="hidden" name="date_time" value={valuationdate} />
      )}
      <div>
        <div className="d-none d-md-block  desktop_view_date">
          <DatePicker
            onSelect={date => handleDateSelect(date)}
            selected={startDate}
            onChange={date => setStartDate(date)}
            showTimeSelect
            showTimeInput
            timeFormat="HH:mm"
            minDate={mindate}
            filterDate={isWeekday}
            name="valuation_date"
            timeIntervals={60}
            dateFormatCalendar={"dd/MM/y"}
            locale="en-gb"
            formatWeekDay={nameOfDay => nameOfDay.substr(0, 1)}
            includeTimes={[
              setHours(
                setMinutes(new Date(), 0),
                moment(startDate).weekday() === 0 ? null : 10
              ),
              setHours(
                setMinutes(new Date(), 0),
                moment(startDate).weekday() === 0 ? null : 11
              ),
              setHours(
                setMinutes(new Date(), 0),
                moment(startDate).weekday() === 0 ? null : 12
              ),
              setHours(
                setMinutes(new Date(), 0),
                moment(startDate).weekday() === 0 ? null : 13
              ),
              setHours(
                setMinutes(new Date(), 0),
                moment(startDate).weekday() === 0 ? null : 14
              ),
              setHours(
                setMinutes(new Date(), 0),
                moment(startDate).weekday() === 0 ? null : 15
              ),
              setHours(
                setMinutes(new Date(), 0),
                moment(startDate).weekday() === 0 ? null : 16
              ),
              setHours(
                setMinutes(new Date(), 0),
                moment(startDate).weekday() === 0 ? null : 17
              ),
              setHours(
                setMinutes(new Date(), 0),
                moment(startDate).weekday() === 6 ||
                  moment(startDate).weekday() === 0
                  ? null
                  : 18
              ),
            ]}
            inline
          />
        </div>
        <div className="d-block d-md-none mobile_view_date">
          <DatePicker
            onSelect={date => handleDateSelect(date)}
            selected={startDate}
            onChange={date => setStartDate(date)}
            showTimeSelect
            showTimeInput
            timeFormat="HH:mm"
            minDate={mindate}
            name="valuation_date"
            filterDate={isWeekday}
            timeIntervals={60}
            dateFormatCalendar={"dd/MM/y"}
            locale="en-gb"
            formatWeekDay={nameOfDay => nameOfDay.substr(0, 1)}
            includeTimes={[
              setHours(
                setMinutes(new Date(), 0),
                moment(startDate).weekday() === 0 ? null : 10
              ),
              setHours(
                setMinutes(new Date(), 0),
                moment(startDate).weekday() === 0 ? null : 11
              ),
              setHours(
                setMinutes(new Date(), 0),
                moment(startDate).weekday() === 0 ? null : 12
              ),
              setHours(
                setMinutes(new Date(), 0),
                moment(startDate).weekday() === 0 ? null : 13
              ),
              setHours(
                setMinutes(new Date(), 0),
                moment(startDate).weekday() === 0 ? null : 14
              ),
              setHours(
                setMinutes(new Date(), 0),
                moment(startDate).weekday() === 0 ? null : 15
              ),
              setHours(
                setMinutes(new Date(), 0),
                moment(startDate).weekday() === 0 ? null : 16
              ),
              setHours(
                setMinutes(new Date(), 0),
                moment(startDate).weekday() === 0 ? null : 17
              ),
              setHours(
                setMinutes(new Date(), 0),
                moment(startDate).weekday() === 6 ||
                  moment(startDate).weekday() === 0
                  ? null
                  : 18
              ),
            ]}
            inline
          />
        </div>
        {moment(startDate).weekday() === 0 && (
          <div className="date-alert">
            Sunday is a holiday, Please choose a valid date.
          </div>
        )}
      </div>
      <Button
        disabled={moment(startDate).weekday() === 0 ? true : false}
        variant="primary"
        className="shadow-none"
        type="submit"
      >
        Confirm Time & Date
      </Button>
      <div className="form-group undefined terms">
        <div className="terms">
          <div>
            By clicking Submit, you agree to our{" "}
            <a href="/terms-and-conditions/">Terms &amp; Conditions</a> and{" "}
            <a href="/privacy-and-cookie-policy/">
              Privacy &amp; Cookie Policy
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CalendarBlock
