import { Link } from "gatsby";
import React, { useEffect, useState, useRef } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import {Button, Navbar, Nav, Form, Container, FormControl, Row, Col, dark, Card, Tabs, Tab, } from "react-bootstrap";
import "./GoogleReviewValuationStep.scss";

import GoogleReviewImgD from "../../../images/ValuationSteps/google-reviews-valuation-steps-d.png";
import GoogleReviewImgT from "../../../images/ValuationSteps/google-reviews-valuation-steps-t.png";
import GoogleReviewImgM from "../../../images/ValuationSteps/google-reviews-valuation-steps-m.png";
import axios from "axios"
import wordsToNumbers from 'words-to-numbers';
const GoogleReviewValuationStep = (props) => {
  const [testimonials, setTestimonials] = useState([])
  const strapiconfig = {
    headers: {
        Authorization:
            `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
    },
  }
  const getitems = async url => {
    try {
      const { data } = await axios.get(url,strapiconfig)// could be from env files
      setTestimonials(data)
    } catch (error) {
      // cache it if fail/error;
      console.error(error)
    }
  }
  useEffect(() => {

    let url = `${process.env.GATSBY_STRAPI_SRC}/google-review-reviews`
    getitems(url);
  }, [])
  var testimonial_count = testimonials ? testimonials.length : 0;
  var rating_count = 0;
  testimonials && testimonials.length > 0 && testimonials.map((item, ind) => {
    rating_count += parseInt(wordsToNumbers(item.starRating));
  })
  var rating_avg = rating_count / testimonial_count;
  return (
    <React.Fragment>
      <ScrollAnimation animateOnce={true} duration={0.5} animateIn="fadeInUp">
      <div className="google-review">
        <div className="goolge-review-img">
        <picture>
          <source media="(min-width:992px)" srcSet={GoogleReviewImgD} />
          <source media="(min-width:768px)" srcSet={GoogleReviewImgT} />
          <img src={GoogleReviewImgM} alt="" />
        </picture>
        </div>
        <div className="google-review-content">
          <span className="text-big">Rated {parseFloat(rating_avg).toFixed(1)}/5 from {testimonial_count} Customer Reviews</span>
          <span className="text-small">An estate agent you can trust.</span>
        </div>
      </div>
      </ScrollAnimation>
    </React.Fragment>
  );
};
export default GoogleReviewValuationStep;
